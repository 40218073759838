import './../css/ChatBox.css'
import ChatBubble from './ChatBubble';

function ChatBox() {
    return (
        <div className='ChatBoxWrap'>
            <div className='nav'> 
                <div>Chats</div>
                <div>Map</div>
            </div>
            <div className='ChatBox'>
                {/* hello */}
                {/* <div>hi</div>
                <div>bye</div> */}
                <ChatBubble user='Bill Nye' text='This is Tylers test bubble' self={true}/>
                <ChatBubble user='Bill Nye' text='This is Caydawgs test bubble' self={false}/>
                <ChatBubble user='Bill Nye' text='This is Tylers test bubble' self={true}/>
                <ChatBubble user='Bill Nye' text='This is Caydawgs test bubble' self={false}/>
                <ChatBubble user='Bill Nye' text='This is Tylers test bubble' self={true}/>
                <ChatBubble user='Bill Nye' text='This is Caydawgs test bubble' self={false}/>
                <ChatBubble user='Bill Nye' text='This is Tylers test bubble' self={true}/>
                <ChatBubble user='Bill Nye' text='This is Caydawgs test bubble' self={false}/>
            </div>
            <input type='text' placeholder='Type a message...'/>
        </div>
    )
}

export default ChatBox;