import logo from './logo.svg';
import './App.css';

import Layout from './components/Layout';
import ChatBox from './components/ChatBox';

function App() {
  return (
    <Layout>
      <ChatBox/>
    </Layout>
  );
}

export default App;
