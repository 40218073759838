import './../css/ChatBubble.css'

function ChatBubble({user, text, self}) {
  return (
    <div className={`chat-bubble-flex ${self?'chat-bubble-flex-right':'chat-bubble-flex-left'}`}>
        <div className={`bubble ${self ? 'bubble-right' : 'bubble-left'}`}>
            {text}
        </div>
    </div>
  );
}

export default ChatBubble;