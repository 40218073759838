import './../css/Layout.css'

function Layout({children}) {

    return (
    <div className='App'>
        <div className='heading'>Platform</div>
        
        {children}
    </div>
    )
}

export default Layout